import { Box, Typography, styled } from "@mui/material";

import { NavLink, useLocation } from "react-router-dom";
import { icons } from "../../assets";

function SideNav(setSidebar: any) {
  const Test = [
    {
      lable: "Dashboard",
      path: "/my-dashboard",
      Icon: icons.MyDashboard,
    },
    {
      lable: "Trade Timetable",
      path: "/my-trade-timetable",
      Icon: icons.MyTradeTimetable,
    },
    {
      lable: "Learning",
      path: "/my-learning",
      Icon: icons.MyLearning,
    },
    {
      lable: "Test",
      path: "/online-test",
      Icon: icons.MyOnlineTest,
    },
    {
      lable: "Doubts",
      path: "/doubts",
      Icon: icons.MyDoubts,
    },
    {
      lable: "E-Books",
      path: "/e-books",
      Icon: icons.MyEBooks,
    },

    {
      lable: "Discussion Forum",
      path: "/my-discussion-forum",
      Icon: icons.MyDiscussionForum,
    },
    {
      lable: "Live Sessions",
      path: "/live-sessions",
      Icon: icons.MyLiveSessions,
    },
    {
      lable: "Notice Board",
      path: "/noticeboard",
      Icon: icons.MyNoticeBoard,
    },
    {
      lable: "Exam Timetable",
      path: "/exam-timetable",
      Icon: icons.MyExamTimetable,
    },
    {
      lable: "User Profile",
      path: "/profile",
      Icon: icons.MyAccountDetails,
    },
  ];
  const location = useLocation();
  return (
    <Box sx={{ ":first-child": { marginTop: "12px" } }}>
      {Test.map((item, key) => (
        <StyledLink to={item.path} onClick={() => {}}>
          <Typography
            sx={{
              color: "#FFFFFF",
              opacity: location.pathname === item.path ? "1" : "0.6",
              fontSize: "14px",
              marginY: "10px",
              mx: 2,
              alignItems: "center",
              display: "flex",
              gap: "10px",
              paddingY: "10px",
              borderRadius: "5px",
              borderLeft:
                location.pathname === item.path ? "6px solid #F4347F" : "",
              backgroundColor: location.pathname === item.path ? "#16ACAB" : "",
              paddingLeft: location.pathname === item.path ? "5px" : "",
            }}
            variant="h3"
          >
            {item.Icon && <img src={item.Icon} />} {item.lable}
          </Typography>
        </StyledLink>
      ))}
    </Box>
  );
}

export default SideNav;

export const StyledLink = styled(NavLink)(({ theme }) => ({
  fontSize: "20px",
  textDecoration: "none",
}));
