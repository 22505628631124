import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getBundleTypes } from "../../../api/services/ebooks";
import { getCourses } from "../../../api/services/learning";
import { icons } from "../../../assets";
import FormOutlinedSelect from "../../../components/FormFields/FormOutlinedSelect";
import { capitalize } from "../../../utils";
import { StyledAbsoleteButton } from "../../NewLoginPage/Pages/Curriculum/Tabs/EBooks";

const Continue = ({ sx }: any) => {
  const [bundleId, setBundleId] = useState("");
  const { data: bundleData } = useQuery("BundleTypes", getBundleTypes);
  const bundleOptions = bundleData?.data?.bundles?.map((item: any) => {
    return {
      value: item?._id,
      label: item?.name,
    };
  });
  const { control, setValue } = useForm({
    defaultValues: {
      bundle: bundleOptions?.length > 0 ? bundleOptions?.[0]?.value : "",
    },
  });
  const { data, isLoading } = useQuery(["getCourses", bundleId], getCourses);

  const activeCourses = data?.data?.courses?.filter(
    (item: any) => item?.continueCourse !== null
  );

  useEffect(() => {
    setValue("bundle", bundleOptions?.[0]?.value);
    setBundleId(bundleOptions?.[0]?.value);
  }, [bundleData]);

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "10px",
        boxShadow: "0px 6px 16px #21468329",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ p: 2, fontWeight: 600 }}>
          Continue where you left off
        </Typography>
        <FormOutlinedSelect
          control={control}
          name="bundle"
          options={bundleOptions || []}
          sx={{ py: 1, px: 2, width: "250px" }}
          defaultValue={bundleOptions?.[0]?.value || ""}
          onChange={(value) => {
            setBundleId(value);
          }}
        />
      </Box>
      <Divider />
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box className="loader" />
        </Box>
      ) : activeCourses?.length > 0 ? (
        <Box sx={{ display: "flex", flexWrap: "wrap", p: 2, gap: 2 }}>
          {activeCourses?.map((item: any) => (
            <ContinueCard bundleId={bundleId} data={item} />
          ))}
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              textAlign: "center",
              alignItems: "center",
              p: 2,
              py: 4,
            }}
          >
            No Active Courses
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Continue;

const ContinueCard = ({ bundleId, data }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const handleCourse = () => {
    searchParams.set("topicId", data?.continueCourse?.topicId);
    searchParams.set("sectionId", data?.continueCourse?.sectionId);
    searchParams.set("chapterId", data?.continueCourse?.chapterId);
    navigate(
      `/learnings/bundle/${bundleId}/course/${
        data?._id
      }?${searchParams.toString()}`
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#F8F8F8",
        width: {
          xs: "100%",
          sm: "100%",
          lg: "49%",
        },
        padding: "10px",
        gap: "20px",
        borderRadius: "15px",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: {
            xs: "130px",
            sm: "220px",
            lg: "350px",
          },
          borderRadius: "10px",
          backgroundImage: `URL(${data?.imageUrl})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          height: "140px",
          border: "1px solid #cecece75",
        }}
      >
        <StyledAbsoleteButton
          sx={{
            width: "110px",
            gap: 1,
          }}
        >
          <img src={icons.book} alt="course" />
          <Typography color={"white"}>Course</Typography>
        </StyledAbsoleteButton>
      </Box>

      <Stack gap={"10px"} sx={{ width: "100%" }}>
        <Typography sx={{ color: "#2F468C", fontSize: "17px" }} variant="h3">
          {capitalize(data?.title)}
        </Typography>
        <Button
          variant="outlined"
          sx={{
            padding: "5px",
            width: {
              xs: "30vw",
              sm: "200px",
              lg: "150px",
            },
            height: {
              xs: "50px",
              sm: "35px",
              lg: "35px",
            },
            fontSize: "14px",
          }}
          onClick={handleCourse}
        >
          Continue Watching
        </Button>
        <>
          <Typography
            sx={{
              textAlign: "right",
              p: 0,
              fontSize: "12px",
              color: "#F4347F",
              fontWeight: 800,
            }}
            variant="subtitle2"
          >
            {Math.round(data?.progress * 100) / 100}% Completed
          </Typography>
          <LinearProgress variant="determinate" value={data?.progress} />
        </>
      </Stack>
    </Box>
  );
};
