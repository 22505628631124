import DehazeIcon from "@mui/icons-material/Dehaze";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { getAllDigitalLibrary } from "../../../api/services";
import { logo } from "../../../assets";
import { MenuItems, NavigationContainer } from "./NavigationContainer";
import {
  NavigationPhoneContainer,
  TradeAccordion,
} from "./NavigationPhoneContainer";

function Header() {
  const { data } = useQuery("get-all-digital-library", getAllDigitalLibrary);
  const isMdUp = useMediaQuery("(min-width:1200px)");
  const isMd = useMediaQuery("(min-width:1000px)");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [exploreOpen, setExploreOpen] = useState(false);

  const open = Boolean(anchorEl);
  const location = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0px 3px 6px #11234B29",
        width: "100%",
        position: "fixed",
        backgroundColor: "#ffffff",
        zIndex: 1000,
        height: "65px",
      }}
    >
      <Box
        sx={{ display: "flex", gap: { xs: 2, lg: 12 }, alignItems: "center" }}
      >
        <Link to={"/"}>
          <Box
            sx={{
              height: { xs: "50px", sm: "55px", lg: "60px", xl: "65px" },
              paddingLeft: {
                xs: "0px",
                sm: "5px",
                md: "15px",
                lg: "25px",
                xl: "35px",
              },
            }}
          >
            <img
              src={logo}
              alt="logo"
              height={"100%"}
              style={{ objectFit: "cover", padding: "10px" }}
            />
          </Box>
        </Link>
        {isMd ? (
          <NavigationContainer buttonLabel={"Explore"} color={"#F4347F"}>
            <MenuItems menuItems={data} />
          </NavigationContainer>
        ) : (
          <NavigationPhoneContainer
            buttonLabel={"Explore"}
            color={"#F4347F"}
            exploreOpen={exploreOpen}
            setExploreOpen={setExploreOpen}
          >
            {exploreOpen && (
              <Box
                sx={{
                  background: "white",
                  borderRadius: "3px",
                  margin: "auto",
                }}
              >
                <TradeAccordion
                  menuItems={data}
                  exploreOpen={exploreOpen}
                  setExploreOpen={setExploreOpen}
                />
              </Box>
            )}
          </NavigationPhoneContainer>
        )}
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        {isMdUp ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
              width: { xs: "100px", sm: "200px", lg: "300px", xl: "400px" },
            }}
          >
            {location.pathname !== "/search" && (
              <Link to={"/search"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                  }}
                >
                  <Typography sx={{ color: "black" }}>Search</Typography>
                  <SearchIcon sx={{ color: "black" }} />
                </Box>
              </Link>
            )}
            {location.pathname !== "/" && (
              <Link to={"/"}>
                <Typography variant="h5"> Login</Typography>
              </Link>
            )}

            <Link to={"https://digitaliti.in/"}>
              <Typography variant="h5" fontSize={"16px"}>
                ITI Web
              </Typography>
            </Link>
            <Link to="registration" style={{marginRight:'30px'}}>
              <Typography variant="h5" fontSize={"16px"}>
                Register
              </Typography>
            </Link>
          </Box>
        ) : (
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            {location.pathname !== "/search" && (
              <Link to={"/search"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                  }}
                >
                  <SearchIcon sx={{ color: "black" }} />
                </Box>
              </Link>
            )}
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ p: 0, width: "max-content" }}
            >
              <DehazeIcon sx={{ color: "black" }} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>
                <Typography sx={{ color: "black" }}>ITI Web</Typography>
              </MenuItem>
              {location.pathname !== "/" && (
                <Link to="/">
                  <MenuItem onClick={handleClose}>
                    <Typography sx={{ color: "black" }}>Login</Typography>
                  </MenuItem>
                </Link>
              )}

              <Link to="/registration">
                <MenuItem onClick={handleClose}>
                  <Typography sx={{ color: "black" }}>Register</Typography>
                </MenuItem>
              </Link>
            </Menu>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Header;

export const StyledButton = styled(Typography)({
  height: "50px",
  padding: "15px",
  width: "100%",
  cursor: "pointer",
});
export const StyledText = styled(Typography)({
  height: "40px",
  padding: "15px",
  width: "100%",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
});

export const StyledTypography = styled(Typography)({
  height: "40px",
  padding: "10px",
  width: "100%",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
});
