import { Button, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { icons } from "../../../../../assets";
import DialogWrapper from "../../../../../components/DialogWrapper";
import { capitalize } from "../../../../../utils";
import { StyledAbsoleteButton } from "../../../../NewLoginPage/Pages/Curriculum/Tabs/EBooks";

export const EBookCard = ({ data }: any) => {
  const isScreenAbove600 = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: 1,
          px: 2,
          width: "100%",
        }}
      >
        <Box>
          <Box sx={{ display: "flex", gap: "25px" }}>
            <Box
              sx={{
                position: "relative",
                width: {
                  xs: "130px",
                  sm: "220px",
                  lg: "220px",
                },
                borderRadius: "10px",
                backgroundColor: "#3E348E29",
                backgroundImage: `URL(${data?.fileUrl})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                objectFit: "contain",
                height: "130px",
              }}
            >
              <StyledAbsoleteButton
                sx={{
                  width: {
                    xs: "100px",
                    sm: "130",
                    lg: "130px",
                  },
                  gap: 1,
                }}
              >
                <img src={icons.book} alt="book" />
                <Typography color={"white"}>E-Book</Typography>
              </StyledAbsoleteButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: 2,
              }}
            >
              {data?.tradeName && (
                <Typography
                  color={"white"}
                  sx={{
                    fontSize: {
                      xs: "13px",
                      sm: "15px",
                      lg: "15px",
                    },
                    width: "max-content",
                    px: "15px",
                    py: "5px",
                    backgroundColor: "#2F468C",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                >
                  {`${data?.tradeName}`}
                </Typography>
              )}

              <Box>
                <Typography sx={{ fontSize: "18px" }} variant="h5">
                  {capitalize(data?.title)}
                </Typography>
                <Box
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                  sx={{
                    fontFamily: "figtree_medium",
                    fontSize: "12px",
                    opacity: "80%",
                    "& *": { p: 0 },
                  }}
                ></Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Button
          variant="contained"
          sx={{ width: "max-content", px: 2, py: 2 }}
          onClick={() => navigate(`/eBook/${data?._id}`)}
        >
          {isScreenAbove600 ? "Start Reading" : "Read"}
        </Button>
      </Box>
    </>
  );
};
